#tooltip[data-popper-placement^='top'] #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] #arrow {
  left: -4px;
}

#arrow, #arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
}

#arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #fff;
}
